import React from 'react';
import * as Sentry from '@sentry/react';

export function useAsyncEffect(fn: () => Promise<void>, deps: Array<unknown>) {
  return React.useEffect(() => {
    fn().catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });
  }, deps);
}
