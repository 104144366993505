import * as React from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import {Controller, useForm, SubmitHandler} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useStores} from 'store/root-store';
import {useLocation, useNavigate, Navigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {ReactComponent as ManoWelcome} from 'assets/mano/mano-welcome.svg';

export const LoginScreen = () => {
  const {authStore} = useStores();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const {handleSubmit, control} = useForm({
    mode: 'onTouched',
  });

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();

  const _onSubmit: SubmitHandler<{email: string; password: string}> = async (
    formValues,
  ) => {
    setLoading(true);

    try {
      await authStore.doLogin(formValues.email, formValues.password);
      setError('');
      navigate(from, {replace: true});
    } catch (e) {
      setError('Wrong email or password. Please try again.');
      console.error(e);
    }

    setLoading(false);
  };

  return (
    <>
      {authStore.token ? (
        <Navigate to="/app/quizzes" />
      ) : (
        <>
          <StyledLoginBackground />
          <Box
            component="form"
            onSubmit={handleSubmit(_onSubmit)}
            sx={loginBoxStyle}>
            <Stack spacing={2}>
              <SvgIcon sx={{width: '100%', height: '100px'}}>
                <ManoWelcome />
              </SvgIcon>
              <Typography variant="h3" fontWeight="bold" textAlign="center">
                Welcome back!
              </Typography>

              <Controller
                name="email"
                control={control}
                defaultValue="" // Needed to suppress warning about controlled/uncontrolled component
                rules={{
                  required: 'Please enter your email address',
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: 'Please enter a valid email address',
                  },
                }}
                render={({field, fieldState}) => (
                  <TextField
                    inputRef={field.ref}
                    label="Email address"
                    value={field.value}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{required: 'Please enter your password'}}
                render={({field, fieldState}) => (
                  <TextField
                    label="Password"
                    value={field.value}
                    inputRef={field.ref}
                    onChange={field.onChange}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {error && <Typography color="error">{error}</Typography>}
              <LoadingButton
                loading={loading}
                variant="outlined"
                type="submit"
                onClick={handleSubmit(_onSubmit)}>
                Login
              </LoadingButton>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

const loginBoxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  maxWidth: 400,
  width: '100%',
  padding: '40px 24px',
};

const StyledLoginBackground = styled('div')(() => ({
  position: 'absolute' as 'absolute',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(-45deg, #F7B032, #F38D1E, #E84C3A, #CD3D32)',
  backgroundSize: '400% 400%',
  animation: 'gradientAnim 10s ease infinite',
  '@keyframes gradientAnim': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));
