import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {Controller, useForm, SubmitHandler} from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import {QuizCategory} from 'screens/QuizDetailScreen';

interface CategoryChangeModalProps {
  category: string;
  error: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<any>;
  open: boolean;
}

export const CategoryChangeModal = (props: CategoryChangeModalProps) => {
  const {
    handleSubmit,
    control,
    formState: {isDirty},
  } = useForm({
    defaultValues: {
      category: props.category,
    },
    mode: 'onChange',
  });

  const _quizCategories: Array<QuizCategory> = [
    'Develop',
    'Dialog',
    'Discover',
    'Explore',
    'Iconic',
    'Milestone',
    'Review',
    'Quiz',
  ];

  const _renderDialogChangeError = () => (
    <Typography color={'error'} sx={{my: 2}}>
      You can not change to a Dialog quiz. Please create a new quiz.
    </Typography>
  );

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
        component="form"
        onSubmit={handleSubmit(props.onSubmit)}>
        {props.category === 'Dialog' ? (
          _renderDialogChangeError()
        ) : (
          <>
            <Controller
              name="category"
              control={control}
              render={({field: {onChange}}) => (
                <>
                  <FormLabel component="legend">Quiz Category</FormLabel>
                  <RadioGroup
                    aria-label="category"
                    defaultValue={props.category}
                    name="radio-buttons-group">
                    {_quizCategories.map((quizCategory) => {
                      return (
                        <FormControlLabel
                          value={quizCategory}
                          control={<Radio onChange={onChange} />}
                          label={quizCategory}
                          key={quizCategory}
                        />
                      );
                    })}
                  </RadioGroup>
                  {props.error && _renderDialogChangeError()}
                </>
              )}
            />
            <Button
              variant="contained"
              disabled={!isDirty}
              onClick={handleSubmit(props.onSubmit)}>
              Change category
            </Button>
          </>
        )}
        <CloseIcon
          onClick={props.onClose}
          style={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Modal>
  );
};
