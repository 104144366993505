import {AsanaTask} from 'api/apiRoutes';
import {DataGridQuestion, QuizData} from 'screens/QuizDetailScreen';
import {SaveModalErrorMessage} from './';

export interface QuizDataGridProps {
  rows: Array<DataGridQuestion>;
  initialRows: Array<DataGridQuestion>;
  quizData: QuizData;
  asanaTasks: Record<string, AsanaTask>;
  selectedRows: ReadonlySet<string>;
  initialScroll: number;
  errors: Array<SaveModalErrorMessage>;
  onRowsChange: (newRows: any, changedRows: any) => void;
  onTriggerReorder: () => void;
  onTriggerDelete: (question: DataGridQuestion) => void;
  onSetSelectedRows: (rows: ReadonlySet<string>) => void;
  onScroll: (e) => void;
}

export interface SearchResult {
  row: number;
  col: number;
  offsetLeft: number;
}

export interface SelectedCell {
  cellElement: Element;
  row: number;
  col: number;
}

export enum Column {
  id = 1,
  count,
  category,
  targetVocab,
  title,
  question,
  answer1,
  answer2,
  answer3,
  answer4,
  answer,
  hint,
  correctMsg,
  incorrectMsg,
}
