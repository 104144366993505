import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import {Button, Container, Skeleton, Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';
import * as Sentry from '@sentry/react';
import {AsanaTask} from 'types/api';
import {DataGridAddRowButton} from 'components/molecules/DataGridAddRowButton';
import {ErrorScreen} from 'components/molecules/ErrorScreen';
import {AddQuestionModal} from 'components/organisms/AddQuestionModal';
import {AddQuestionsBlockModal} from 'components/organisms/AddQuestionsBlockModal';
import {DeleteQuestionModal} from 'components/organisms/DeleteQuestionModal';
import {
  PageHeader,
  PageHeaderActionButton,
} from 'components/organisms/PageHeader';
import {QuizDataGrid} from 'components/organisms/QuizDataGrid';
import {
  ReorderItem,
  ReorderItemsModal,
} from 'components/organisms/ReorderItemsModal';
import {ChangesTableItem, SaveModal} from 'components/organisms/SaveModal';
import {SpeedDial} from 'components/organisms/SpeedDial';
import {VideoModal} from 'components/organisms/VideoModal';
import {
  accessibilityLabels,
  getAutofillTemplateQuestion,
  getQuestionBlockByTitle,
  strings,
} from 'settings';
import {extractIdFromPlaceholder} from 'helper';
import {useAsyncEffect} from 'helper/useAsyncEffect';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {useSnackbar} from 'notistack';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useStores} from 'store/root-store';
import {QuestionCategoryAutoComplete, QuestionField} from 'types';
import {
  DataGridQuestion,
  QuizCategory,
  QuizData,
  SaveModalErrorMessage,
} from './';
import {getValidatedQuestion} from './QuizDetailValidation.service';
import {Observer} from 'mobx-react';
import {observer} from 'mobx-react-lite';
import {useStateWithGetter} from 'hooks/useStateWithGetter';

const EMPTY_ASANA_TASKS = {};

export const QuizDetailScreen = observer(() => {
  const [quizData, setQuizData] = React.useState<QuizData>({});
  const [dirty, setDirty] = React.useState(false);
  const [invalidQuiz, setInvalidQuiz] = React.useState<
    Array<SaveModalErrorMessage>
  >([]);
  const [error, setError] = React.useState<{message: string; title: string}>();
  const [loading, setLoading] = React.useState(true);
  const [questionModalVisible, setQuestionModalVisible] = React.useState(false);
  const [questionsBlockModalVisible, setQuestionsBlockModalVisible] =
    React.useState(false);
  const [presaveModalVisible, setPresaveModalVisible] = React.useState(false);
  const [reorderItemsModalVisible, setReorderItemsModalVisible] =
    React.useState(false);
  const [deleteQuestionModalVisible, setDeleteQuestionModalVisible] =
    React.useState(false);
  const [reorderItems, setReorderItems] = React.useState<Array<ReorderItem>>(
    [],
  );
  const [deleteQuestionItem, setDeleteQuestionItem] =
    React.useState<DataGridQuestion>();
  const [questions, setQuestions, getQuestions] = useStateWithGetter<
    Array<DataGridQuestion>
  >([]);
  const [storedQuestions, setStoredQuestions] = React.useState<
    Array<DataGridQuestion>
  >(cloneDeep(questions));
  const [presaveChanges, setPresaveChanges] = React.useState<
    Array<ChangesTableItem>
  >([]);

  const [selectedRows, setSelectedRows, getSelectedRows] = useStateWithGetter<
    ReadonlySet<string>
  >(new Set());

  const [lastScrollPosition, setLastScrollPosition] = React.useState(0);

  const {enqueueSnackbar} = useSnackbar();

  const {authStore, quizStore} = useStores();
  const params = useParams();
  const {handleSubmit, reset, control} = useForm({
    defaultValues: {questionCategory: '', targetVocab: ''},
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [asanaTasks, setAsanaTasks] =
    React.useState<Record<AsanaTask['id'], AsanaTask>>(EMPTY_ASANA_TASKS);
  useAsyncEffect(async () => {
    if (!authStore.token) {
      setAsanaTasks(EMPTY_ASANA_TASKS);
      return;
    }

    const response = await authStore.apiGetAsanaTasks(params.quizId);
    const tasks = {};
    for (const task of response) {
      const cell = tasks[task.questionId]?.[task.column] ?? [];
      cell.push(task);
      set(tasks, [task.questionId, task.column], cell);
    }
    setAsanaTasks(tasks);
  }, [params.quizId]);

  React.useEffect(() => {
    _fetchData();
  }, [params.quizId]);

  React.useEffect(() => {
    document.addEventListener('keydown', _keyDownListener, false);

    return () => {
      document.removeEventListener('keydown', _keyDownListener, false);
    };
  }, []);

  React.useEffect(() => {
    _checkQuestionsForErrors();
  }, [questions]);

  React.useEffect(() => {
    if (!presaveModalVisible) {
      return;
    }

    setPresaveChanges([]);

    const changes: Array<ChangesTableItem> = [];
    const comparableFields: Array<QuestionField> = [
      'status',
      'trainerStatus',
      'title',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'hint',
      'correctMsg',
      'incorrectMsg',
    ];

    questions.forEach((question, i) => {
      comparableFields.forEach((field) => {
        if (!storedQuestions[i]) {
          if (question[field]) {
            changes.push({
              field: field,
              count: question.count,
              oldValue: '##new##',
              newValue: question[field],
            });
          }

          return;
        }

        if (question[field] !== storedQuestions[i][field]) {
          changes.push({
            field: field,
            count: question.count,
            oldValue: storedQuestions[i][field],
            newValue: question[field],
          });
        }
      });
    });

    setPresaveChanges(changes);
  }, [presaveModalVisible]);

  const _keyDownListener = (event) => {
    if (event.keyCode === 83 && event.metaKey) {
      event.preventDefault();
      _openPresaveModal();
    }

    // CMD + E
    if (event.keyCode === 69 && event.metaKey) {
      event.preventDefault();
      _onAddQuestionButtonClick('single');
    }

    if (event.keyCode === 67 && event.metaKey) {
      // CMD + C
      const numberOfQuestions = getSelectedRows().size;
      if (numberOfQuestions === 0) {
        return;
      }

      event.preventDefault();
      _copySelectedQuestions(numberOfQuestions);
    }

    if (event.keyCode === 86 && event.metaKey) {
      // CMD + V

      window.navigator.clipboard.readText().then(
        (copiedText) => {
          if (!copiedText.startsWith('lingvanocms://')) {
            return;
          }

          event.preventDefault();
          _pasteSelectedQuestions(copiedText);
          setSelectedRows(new Set([]));
        },
        () => {
          enqueueSnackbar('You need to permit permission to paste elements', {
            variant: 'error',
          });
        },
      );
    }
  };

  const _fetchData = async () => {
    setLoading(true);
    document.title = 'Loading | Lingvano CMS';

    setQuizData({});

    setDirty(false);
    setError(undefined);
    setInvalidQuiz([]);

    setQuestions([]);

    setPresaveModalVisible(false);
    setQuestionModalVisible(false);
    setReorderItemsModalVisible(false);
    setDeleteQuestionModalVisible(false);
    quizStore.videoModal.onClose();

    try {
      const quiz = await authStore.apiGetQuiz(params.quizId);

      const quizData: QuizData = {
        category: quiz.category,
        lessonId: quiz.lessonId,
        title: quiz.title,
        url: quiz.url,
        readOnly: quiz.title.includes('vocab_trainer'),
      };
      setQuizData(quizData);
      document.title = `${quiz.title} | Lingvano CMS`;

      const validatedRows = _prepareRows(
        quiz.questions as unknown as Array<DataGridQuestion>,
        quiz.category,
      );

      setQuestions(validatedRows);
      setStoredQuestions(cloneDeep(validatedRows));
    } catch (e) {
      setError({message: e.message, title: strings.QUIZ_FAILED});
      Sentry.captureException(e);
    }

    setDirty(false);
    setLoading(false);
  };

  const _renderQuiz = () => {
    if (loading) {
      return _renderDataGridLoading();
    }

    if (error) {
      if (error.message.includes('401')) {
        authStore.logout(navigate, location);
      }
      return <ErrorScreen message={error.message} title={error.title} />;
    }

    return (
      <>
        {_renderQuizQuestions()}
        {questionModalVisible && (
          <AddQuestionModal
            control={control}
            onSubmit={handleSubmit(_onAddQuestionSubmit)}
            onClose={() => setQuestionModalVisible(false)}
          />
        )}
        {questionsBlockModalVisible && (
          <AddQuestionsBlockModal
            quizCategory={quizData.category}
            onSubmit={_onAddQuestionBlockSubmit}
            onClose={() => setQuestionsBlockModalVisible(false)}
          />
        )}
        {presaveModalVisible && (
          <SaveModal
            mode={invalidQuiz.length ? 'error' : 'changes'}
            changes={presaveChanges}
            errors={invalidQuiz}
            onSave={_handleSaveAction}
            onClose={() => setPresaveModalVisible(false)}
          />
        )}
        {reorderItemsModalVisible && (
          <ReorderItemsModal
            items={reorderItems}
            onSubmit={_handleReorderAction}
            onClose={() => setReorderItemsModalVisible(false)}
          />
        )}
        {deleteQuestionModalVisible && (
          <DeleteQuestionModal
            question={deleteQuestionItem}
            onDelete={_handleDeleteQuestionAction}
            onClose={() => setDeleteQuestionModalVisible(false)}
          />
        )}
        <Observer>
          {() => (
            <>
              {quizStore.videoModal.visible ? <VideoModal /> : null}
              {quizStore.videoModal.update ? _videoModalUpdate() : null}
            </>
          )}
        </Observer>
        {!quizData.readOnly && _renderSpeedDial()}
      </>
    );
  };

  const _renderQuizQuestions = () => {
    return questions.length ? (
      <Box mt={2}>
        <QuizDataGrid
          errors={invalidQuiz}
          asanaTasks={asanaTasks}
          initialRows={storedQuestions}
          rows={questions}
          quizData={quizData}
          initialScroll={lastScrollPosition}
          onRowsChange={_onRowsChange}
          onTriggerReorder={_onTriggerReorder}
          onTriggerDelete={(item) => {
            _onTriggerDelete(item);
          }}
          selectedRows={selectedRows}
          onSetSelectedRows={setSelectedRows}
          onScroll={(e) => setLastScrollPosition(e.target.scrollTop)}
        />
        {!quizData.readOnly && (
          <DataGridAddRowButton
            buttons={[
              {
                title: strings.QUIZ_ADD_QUESTION,
                icon: <AddCircleOutlineOutlinedIcon />,
                type: 'single',
              },
              {
                title: strings.QUIZ_ADD_QUESTION_BLOCK,
                icon: <ViewAgendaIcon />,
                type: 'block',
              },
            ]}
            onPress={(type) => _onAddQuestionButtonClick(type)}
          />
        )}
      </Box>
    ) : (
      _renderEmptyQuizScreen()
    );
  };

  const _renderDataGridLoading = () => {
    const skeletonStyle = {
      transform: 'scale(1)',
      marginBottom: '8px',
      marginTop: '8px',
    };

    const numberOfBars = 4;

    return (
      <Box mt={6} data-testid="quiz-data-grid-loading">
        {Array.from({length: numberOfBars}, (_, k) => (
          <Skeleton
            variant="text"
            animation="wave"
            width="100%"
            height={40}
            sx={skeletonStyle}
            key={k}
          />
        ))}
      </Box>
    );
  };

  const _renderEmptyQuizScreen = () => {
    return (
      <Container maxWidth="md">
        <Stack direction="column" alignItems="flex-start" spacing={2} mt={2}>
          <Typography>{strings.QUIZ_NO_QUESTIONS}</Typography>
          <Button
            variant="contained"
            onClick={() => _onAddQuestionButtonClick('single')}>
            {strings.QUIZ_ADD_FIRST_QUESTION}
          </Button>
          <Button
            variant="contained"
            onClick={() => _onAddQuestionButtonClick('block')}>
            {strings.QUIZ_ADD_QUESTION_BLOCK}
          </Button>
        </Stack>
      </Container>
    );
  };

  const _prepareRows = (
    rows: Array<DataGridQuestion>,
    quizCategory: QuizCategory,
    targetVocabUpdate?: {key: number; oldValue: string},
  ) => {
    return rows.map((thisRow, i) => {
      if (quizCategory === 'Dialog') {
        thisRow = _updateDialogFields(thisRow);
      }

      if (i === targetVocabUpdate?.key) {
        return getValidatedQuestion(
          thisRow,
          quizData.category,
          targetVocabUpdate.oldValue,
        );
      }

      return getValidatedQuestion(thisRow, quizData.category);
    });
  };

  const _getInitialQuestionValues = (
    category: QuestionCategoryAutoComplete,
    targetVocabValue: string,
    count?: string,
  ) => {
    const autoFilledQuestion = getAutofillTemplateQuestion(category.id);

    return {
      ...autoFilledQuestion.defaultValues,
      targetVocab: targetVocabValue,
      count: count ? count : String(questions.length + 1),
      category: category.id,
    } as DataGridQuestion;
  };

  const _onRowsChange = (newRows, changedRows) => {
    setDirty(true);

    if (changedRows?.column?.key === 'correctMsg') {
      newRows = _syncCorrectIncorrectMessage(newRows, changedRows.indexes[0]);
    }

    let targetVocabUpdate: any = {};

    if (changedRows?.column?.key === 'targetVocab') {
      targetVocabUpdate.oldValue =
        questions[changedRows.indexes[0]].targetVocab;
      targetVocabUpdate.key = changedRows.indexes[0];
    }

    const prepared = _prepareRows(
      newRows,
      quizData.category,
      targetVocabUpdate,
    );

    setQuestions(prepared);

    const _changedQuestions = _getChangedQuestions(cloneDeep(prepared));
    if (!_changedQuestions.length) {
      setDirty(false);
    }
  };

  const _syncCorrectIncorrectMessage = (rows, index) => {
    if (questions[index].correctMsg === questions[index].incorrectMsg) {
      rows[index]['incorrectMsg'] = rows[index]['correctMsg'];
    }

    return rows;
  };

  const _checkQuestionsForErrors = (): void => {
    const quizErrors: Array<SaveModalErrorMessage> = [];

    questions.forEach((question) => {
      if (question?.errors?.length) {
        const errorFields = question.errors.map((error) => String(error.field));
        const uniqueErrors = errorFields.filter(function (item, pos, self) {
          return self.indexOf(item) === pos;
        });
        const fieldString = uniqueErrors.join(', ');

        quizErrors.push({
          count: question.count,
          fields: fieldString,
        });
      }
    });

    setInvalidQuiz(quizErrors);
  };

  const _handleSaveAction = async () => {
    setLoading(true);

    const _changedQuestions = _getChangedQuestions();

    try {
      const _updatedQuiz = await authStore.apiPostQuestions(
        _changedQuestions,
        params.quizId,
      );

      if (_updatedQuiz.success) {
        _fetchData();
      }
    } catch (e) {
      setError({message: e.message, title: strings.QUIZ_SAVE_FAILED});
    }

    setPresaveModalVisible(false);
  };

  const _onTriggerReorder = () => {
    const _changedQuestions = _getChangedQuestions();
    let reorderItems: Array<ReorderItem> = [];

    if (!_changedQuestions.length) {
      reorderItems = questions.map((question) => {
        const reorderItem: ReorderItem = {
          id: question.id,
          title: question.title,
          content: question.question,
          count: question.count,
          category: question.category,
        };

        return reorderItem;
      });
    }

    setReorderItems(reorderItems);
    setReorderItemsModalVisible(true);
  };

  const _handleReorderAction = async (reorderItems: Array<ReorderItem>) => {
    setLoading(true);

    const newOrder = reorderItems.map((reorderItem) => reorderItem.id);

    try {
      await authStore.apiPostQuestionsOrder(newOrder, params.quizId);
      _fetchData();
    } catch (e) {
      setLoading(false);
      setError({message: e.message, title: strings.QUIZ_SAVE_FAILED});
    }

    setReorderItems([]);
    setReorderItemsModalVisible(false);
  };

  const _onTriggerDelete = (deleteItem: DataGridQuestion) => {
    if (!deleteItem.id) {
      // We allow to delete new & unsaved questions instantly

      setQuestions((currentQuestions) => {
        const questionsWithoutCurrent = currentQuestions.filter(
          (question) => question.count !== deleteItem.count,
        );

        let count = 0;
        questionsWithoutCurrent.forEach((question) => {
          question.count = String((count += 1));
        });
        return questionsWithoutCurrent;
      });
      return;
    }

    const _changedQuestions = _getChangedQuestions();

    if (!_changedQuestions.length) {
      setDeleteQuestionItem(deleteItem);
      setDeleteQuestionModalVisible(true);
      return;
    }

    setDeleteQuestionItem(undefined);
    setDeleteQuestionModalVisible(true);
  };

  const _handleDeleteQuestionAction = async () => {
    setLoading(true);

    try {
      const _deleteResponse = await authStore.apiDeleteQuestion(
        params.quizId,
        deleteQuestionItem.id,
      );

      if (_deleteResponse.success) {
        _fetchData();
      }
    } catch (e) {
      setError({message: e.message, title: strings.QUIZ_SAVE_FAILED});
    }

    setDeleteQuestionItem(undefined);
    setDeleteQuestionModalVisible(false);
  };

  const _getChangedQuestions = (
    importedQuestions?,
  ): Array<DataGridQuestion> => {
    const currentQuestions = importedQuestions
      ? importedQuestions
      : cloneDeep(questions);

    const _changedQuestions = currentQuestions.filter(
      (question: DataGridQuestion, questionIndex: number) => {
        if (
          JSON.stringify(question) !==
          JSON.stringify(storedQuestions[questionIndex])
        ) {
          if (quizData.category === 'Dialog') {
            question = _updateDialogFieldPresave(question);
          }

          return true;
        }
        return false;
      },
    );

    return _changedQuestions;
  };

  const _navigate = useNavigate();
  const _renderSpeedDial = () => {
    return (
      <SpeedDial
        ariaLabel={accessibilityLabels.QUIZ.QUICK_ACTIONS}
        actions={[
          {
            icon: <ShareIcon />,
            name: strings.QUIZ_COPY_URL,
            action: () => {
              _copyCurrentUrl();
            },
          },
          {
            icon: <OpenInNewIcon />,
            name: strings.QUIZ_OPEN,
            action: () => {
              _openQuizInWeb();
            },
          },
          {
            icon: <VideoCameraFrontOutlinedIcon />,
            name: strings.QUIZ_EXPORT_TPP,
            action: () => {
              _navigate(
                `/app/${authStore.language}/lessons/${quizData.lessonId}`,
                {
                  replace: true,
                },
              );
            },
          },
          {
            icon: <AddCircleOutlineOutlinedIcon />,
            name: strings.QUIZ_ADD_QUESTION,
            action: () => {
              _onAddQuestionButtonClick('single');
            },
          },
        ]}
      />
    );
  };

  const _onAddQuestionButtonClick = (type: 'block' | 'single') => {
    if (type === 'block') {
      setQuestionsBlockModalVisible(true);
      return;
    }

    setQuestionModalVisible(true);
  };

  const _onAddQuestionSubmit = (data) => {
    const autoFillValues = _getInitialQuestionValues(
      data.questionCategory,
      data.targetVocab,
      data.count,
    );

    if (quizData.category === 'Dialog') {
      autoFillValues['question'] = '';
    }

    setQuestions((prevValue) => {
      const prepared = _prepareRows(
        [...prevValue, autoFillValues],
        quizData.category,
      );
      return prepared;
    });

    reset({questionCategory: '', targetVocab: ''});
    setQuestionModalVisible(false);
    setDirty(true);

    if (!data.count) {
      enqueueSnackbar('Added new Question', {variant: 'success'});
    }
  };

  const _onAddQuestionBlockSubmit = (data) => {
    const thisQuestionBlock = getQuestionBlockByTitle(data.questionBlockType);

    if (!thisQuestionBlock) {
      return;
    }

    thisQuestionBlock.questions.forEach(async (question, index) => {
      _onAddQuestionSubmit({
        questionCategory: {id: question.category},
        targetVocab:
          data['targetVocab' + extractIdFromPlaceholder(question.targetVocab)],
        count: String(questions.length + 1 + index),
      });
    });

    enqueueSnackbar(
      `Added Question Block  ${data.questionBlockType} (${thisQuestionBlock.questions.length} questions)`,
      {variant: 'success'},
    );
  };

  const _openPresaveModal = () => {
    setPresaveChanges([]);

    const changes: Array<ChangesTableItem> = [];
    const comparableFields: Array<QuestionField> = [
      'title',
      'trainerStatus',
      'question',
      'answer1',
      'answer2',
      'answer3',
      'answer4',
      'answer',
      'hint',
      'correctMsg',
      'incorrectMsg',
    ];
    const checkQuestions = cloneDeep(questions);

    checkQuestions.forEach((question: DataGridQuestion, i: number) => {
      if (quizData.category === 'Dialog') {
        question = _updateDialogFieldPresave(question);
      }

      comparableFields.forEach((field) => {
        if (!storedQuestions[i]) {
          if (question[field]) {
            changes.push({
              field: field,
              count: question.count,
              oldValue: '* newly created question *',
              newValue: question[field],
            });
          }

          return;
        }

        let thisStoredQuestion = cloneDeep(storedQuestions[i]);

        if (quizData.category === 'Dialog') {
          thisStoredQuestion = _updateDialogFieldPresave(thisStoredQuestion);
        }

        if (question[field] !== thisStoredQuestion[field]) {
          changes.push({
            field: field,
            count: question.count,
            oldValue: thisStoredQuestion[field],
            newValue: question[field],
          });
        }
      });
    });

    setPresaveChanges(changes);
    setPresaveModalVisible(true);
  };

  const _openQuizInWeb = () => {
    if (quizData && quizData.url) {
      window.open(quizData.url, '_blank');
    }
  };

  const _copyCurrentUrl = () => {
    window.navigator.clipboard.writeText(window.location.href);

    // TODO: Add snackbar event!
  };

  const _actionButtons: Array<PageHeaderActionButton> = [
    {
      onPress: async () => {
        setLoading(true);
        await authStore.apiPostAutofillVideos(params.quizId);
        _fetchData();
      },
      icon: <VideoSettingsIcon />,
      disabled: dirty,
      tooltip: dirty
        ? strings.QUIZ_AUTOFILL_VIDEOS.DISABLED
        : strings.QUIZ_AUTOFILL_VIDEOS.ENABLED,
      ariaLabel: 'autofill-video-ids',
    },
    {
      title: strings.QUIZ_REVERT,
      onPress: () => {
        // TODO: Add a warning about unsaved questions before resetting

        setQuestions(cloneDeep(storedQuestions));
        setDirty(false);
      },
      icon: <CancelIcon />,
      disabled: !dirty,
      variant: 'outlined',
      tooltip: strings.QUIZ_REVERT_INFO,
    },
    {
      title: strings.QUIZ_SAVE,
      onPress: _openPresaveModal,
      icon: <SaveIcon />,
      disabled: !dirty,
    },
  ];

  const _videoModalUpdate = () => {
    try {
      setDirty(true);

      const updatedQuestions = cloneDeep(questions);

      const oldValue =
        updatedQuestions[quizStore.videoModal.questionIndex][
          quizStore.videoModal.field
        ];
      const newValue = oldValue.replace(
        quizStore.videoModal.prevValue,
        _generateVideoModalShortcode(),
      );

      updatedQuestions[quizStore.videoModal.questionIndex][
        quizStore.videoModal.field
      ] = newValue;

      setQuestions(updatedQuestions);

      const _changedQuestions = _getChangedQuestions(
        cloneDeep(updatedQuestions),
      );
      if (!_changedQuestions.length) {
        setDirty(false);
      }

      quizStore.videoModal.onClose();
    } catch {
      quizStore.videoModal.onClose();

      enqueueSnackbar('Error while trying to update Video', {
        variant: 'error',
      });
    }
    return null;
  };

  const _generateVideoModalShortcode = () => {
    let shortcode = '[qvideo';

    if (quizStore.videoModal.id) {
      shortcode += ` id="${quizStore.videoModal.id}"`;
    }

    if (quizStore.videoModal.description) {
      shortcode += ` description="${quizStore.videoModal.description}"`;
    }

    if (quizStore.videoModal.solution) {
      shortcode += ` solution="${quizStore.videoModal.solution}"`;
    }

    if (quizStore.videoModal.person === 'b') {
      shortcode += ' person="b"';
    }

    shortcode += ']';

    return shortcode;
  };

  const _updateDialogFields = (
    question: DataGridQuestion,
  ): DataGridQuestion => {
    if (question['question']) {
      const hasParagraph = question['question'].match(/<p>(.*)<\/p>/);

      if (hasParagraph) {
        question['question'] = question['question'].replace(/<p>(.*)<\/p>/, '');
        question['title'] = hasParagraph[1];
      }
    }

    return question;
  };

  const _updateDialogFieldPresave = (
    question: DataGridQuestion,
  ): DataGridQuestion => {
    if (question['title']) {
      question['question'] += '<p>' + question['title'] + '</p>';
      question['title'] = '';
    }

    return question;
  };

  const _copySelectedQuestions = (numberOfQuestions: number) => {
    const selectedQuestions = [];

    for (let item of getSelectedRows()) {
      selectedQuestions.push(
        getQuestions().find((question) => question.count === item),
      );
    }

    const toCopy = 'lingvanocms://' + JSON.stringify(selectedQuestions);

    window.navigator.clipboard.writeText(toCopy).then(
      () => {
        enqueueSnackbar(
          `Copied ${numberOfQuestions} question${
            numberOfQuestions > 1 ? 's' : ''
          }`,
          {variant: 'info'},
        );
      },
      () => {
        enqueueSnackbar('Could not copy questions', {variant: 'error'});
      },
    );
  };

  const _pasteSelectedQuestions = (clipboard: string) => {
    const questionsFromClipboard = JSON.parse(
      clipboard.replace('lingvanocms://', ''),
    );

    const newQuestions = questionsFromClipboard.map((newQuestion, index) => ({
      ...newQuestion,
      id: undefined,
      count: String(getQuestions().length + 1 + index),
    }));

    setQuestions([...getQuestions(), ...newQuestions]);
    setDirty(true);

    enqueueSnackbar(
      `Successfully added ${questionsFromClipboard.length} question${
        questionsFromClipboard.length > 1 ? 's' : ''
      }`,
      {variant: 'success'},
    );
  };

  return (
    <>
      <PageHeader
        loading={loading}
        title={quizData.title}
        category={quizData.category}
        actionButtons={
          quizData?.title?.includes('vocab_trainer') ? [] : _actionButtons
        }
      />
      <Container
        sx={{
          maxWidth: 'none !important',
          px: {xs: 1, xl: 3},
          position: 'relative',
        }}>
        {_renderQuiz()}
      </Container>
    </>
  );
});
