import React from 'react';
import * as Navigation from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import {strings} from 'settings';
import {PageHeader} from 'components/organisms/PageHeader';
import {useStores} from 'store/root-store';
import {TeleprompterModal} from 'components/organisms/TeleprompterModal';
import {LessonSkeleton} from './LessonSkeleton';
import {Box} from '@mui/system';
import {UnauthorizedApiRequest} from 'settings/exceptions';
import * as Sentry from '@sentry/react';
import {TeleprompterMode} from 'types';

export const LessonScreen = () => {
  const [loading, setLoading] = React.useState(false);
  const [generatingTpp, setGeneratingTpp] = React.useState(false);
  const [tppOpen, setTppOpen] = React.useState(false);
  const [targetVocabs, setTargetVocabs] = React.useState<Array<string>>([]);
  const generatorTitle = generatingTpp
    ? strings.TPP.LOADING
    : strings.TPP.GENERATE;

  const [title, setTitle] = React.useState('');
  const [subtitle, setSubtitle] = React.useState('');
  const [mode, setMode] = React.useState<TeleprompterMode>('person-a');

  const {authStore} = useStores();
  const params = Navigation.useParams();
  const {enqueueSnackbar} = useSnackbar();
  const location = Navigation.useLocation();

  const navigate = Navigation.useNavigate();

  React.useEffect(() => {
    const _fetchLesson = async () => {
      setLoading(true);

      try {
        const getLesson = await authStore.apiGetLesson(Number(params.lessonId));

        setTitle(getLesson.title);
        setSubtitle(getLesson.subtitle);
      } catch (e) {
        if (e instanceof UnauthorizedApiRequest) {
          authStore.logout(navigate, location);
        } else {
          alert(e.message);
        }
        Sentry.captureException(e);
      }

      setLoading(false);
    };

    _fetchLesson();
  }, [params.lessonId]);

  const _generateTeleprompterPresentation = async () => {
    setGeneratingTpp(true);

    enqueueSnackbar('Started generating TPP', {
      variant: 'info',
    });

    try {
      const getTargetVocabs = await authStore.apiGetTeleprompter({
        mode,
        chapterId: Number(params.lessonId),
      });

      setTargetVocabs(
        getTargetVocabs.words.map((v) => `${v.prefix} ${v.word}`),
      );
    } catch (e) {
      const errorMessage = `TPP could not be created: ${e.message}`;
      console.error(errorMessage);
      alert(errorMessage);
    }

    setGeneratingTpp(false);
    enqueueSnackbar('Finished generating TPP', {
      variant: 'success',
    });
  };

  const radios = [
    {
      title: strings.TPP.GENERATE_PERSONA,
      mode: 'person-a',
    },
    {
      title: strings.TPP.GENERATE_PERSONB,
      mode: 'person-b',
    },
    {
      title: strings.TPP.GENERATE_DIALOG,
      mode: 'dialog',
    },
  ];

  if (loading) {
    return <LessonSkeleton />;
  }

  return (
    <>
      <PageHeader title={title} subtitle={subtitle} />
      <Container maxWidth="md" sx={{marginY: 4}}>
        <Box sx={{mb: 2}}>
          <Typography>{strings.TPP.LABEL}</Typography>
          <RadioGroup defaultValue="person-a" name="tpp-export-type">
            {radios.map((radio) => (
              <FormControlLabel
                key={radio.mode}
                value={radio.mode}
                control={
                  <Radio
                    onChange={(e) =>
                      setMode(e.target.value as TeleprompterMode)
                    }
                  />
                }
                label={radio.title}
              />
            ))}
          </RadioGroup>
        </Box>
        <LoadingButton
          sx={{mr: 2}}
          loading={generatingTpp}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
          loadingPosition="start"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={_generateTeleprompterPresentation}
          disabled={generatingTpp}>
          {generatorTitle}
        </LoadingButton>
        {targetVocabs.length ? (
          <>
            <Button
              disabled={generatingTpp}
              startIcon={<SlideshowIcon />}
              onClick={() => setTppOpen(true)}
              variant="contained">
              {strings.TPP.OPEN}
            </Button>
            <TeleprompterModal
              isOpen={tppOpen}
              slides={targetVocabs}
              onClose={() => setTppOpen(false)}
            />
          </>
        ) : null}
      </Container>
    </>
  );
};
