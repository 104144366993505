export const asanaUsers = {
  success: true,
  response: [
    {gid: '1160894679374311', name: 'Liz'},
    {gid: '1200891286612484', name: 'Dejan'},
    {gid: '1203655548620912', name: 'Johanna'},
    {gid: '1149555044647056', name: 'Matthias'},
    {gid: '1149556515643882', name: 'Nico'},
    {gid: '1192094581392400', name: 'Milena'},
    {gid: '1201599597875903', name: 'Daniel'},
    {gid: '1201991891898617', name: 'Mišo'},
    {gid: '1201092825983158', name: 'Christof'},
    {gid: '1202363174631986', name: 'Niklas'},
    {gid: '1203655416283412', name: 'Lisa'},
    {gid: '1202216729554050', name: 'Genie'},
    {gid: '1208100357788153', name: 'Ani'},
    {gid: '1207526449859488', name: 'Cat'},
    {gid: '1204413274038791', name: 'Cecilia'},
    {gid: '1204508360973589', name: 'Elena'},
    {gid: '1204508619547737', name: 'Gabe'},
    {gid: '1206347856007221', name: 'Gabriel'},
    {gid: '1206484898690322', name: 'José'},
    {gid: '1208286004429696', name: 'Maor'},
    {gid: '1207939949153112', name: 'Natalie'},
    {gid: '1206566788680006', name: 'Nil'},
    {gid: '1207703624562960', name: 'Sara'},
    {gid: '1206484895456726', name: 'Tatjana'},
    {gid: '1207382919653869', name: 'Tereza'},
  ],
};
