import React from 'react';
import {Button} from '@mui/material';
import {strings} from 'settings';
import {DndProvider} from 'react-dnd';
import DialogItem from './DialogModalItemView';
import update from 'immutability-helper';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {getDialogsShortcodeProperties} from 'helper';
import {Modal} from 'components/molecules/Modal';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import uniqueId from 'lodash/uniqueId';
import {HTML5BackendManager} from 'helper/reactDnD';

interface DialogModalProps {
  editor: any;
  onSubmit: () => void;
  onClose: () => void;
}

export interface DialogForm {
  id?: number;
  subtitle: string;
  person: 'a' | 'b';
  key: string;
}

export const DialogModal = (props: DialogModalProps) => {
  const [dialogs, setDialogs] = React.useState<Array<DialogForm>>([]);

  React.useEffect(() => {
    const dialogShortcodes = getDialogsShortcodeProperties(
      props.editor.row.question,
    );
    if (dialogShortcodes) {
      setDialogs(dialogShortcodes);
    }
  }, []);

  React.useEffect(() => {
    _generateDialogContent();
  }, [dialogs]);

  const _generateDialogContent = () => {
    let questionVal = '';

    dialogs.forEach((conversation) => {
      const thisId = conversation.id ? ` id="${conversation.id}"` : '';
      const thisSubtitle =
        conversation.subtitle !== ''
          ? ` subtitle="${conversation.subtitle}"`
          : '';
      const thisPerson = ` person="${conversation.person}"`;

      questionVal += `[lv-dialog${thisId}${thisSubtitle}${thisPerson}]`;
    });

    props.editor.onRowChange({
      ...props.editor.row,
      question: questionVal,
    });
  };

  const _deleteDialog = (dialogToDelete) => {
    const newDialogs = dialogs;

    const index = newDialogs.findIndex((dialog) => dialog === dialogToDelete);
    newDialogs.splice(index, 1);

    setDialogs(newDialogs);
    _generateDialogContent();
  };

  const _moveDialog = (dialogToMove, direction) => {
    const newDialogs = dialogs;

    const fromIndex = newDialogs.findIndex((dialog) => dialog === dialogToMove);
    const toIndex = fromIndex + (direction === 'up' ? -1 : 1);

    const dialog = newDialogs.splice(fromIndex, 1)[0];
    newDialogs.splice(toIndex, 0, dialog);

    setDialogs(newDialogs);
    _generateDialogContent();
  };

  const _renderDialogs = () => {
    return (
      <DndProvider manager={HTML5BackendManager}>
        <div data-testid="dv-modal-list">
          {dialogs.map((dialog, i) => {
            return (
              <DialogItem
                key={dialog.key}
                dialog={dialog}
                index={i}
                lastItem={i === dialogs.length - 1}
                id={dialog.id}
                reorderItem={_reorderItem}
                generateDialogContent={_generateDialogContent}
                deleteDialog={(toDelete) => {
                  _deleteDialog(toDelete);
                }}
                moveDialog={(toMove, direction) => {
                  _moveDialog(toMove, direction);
                }}
              />
            );
          })}
        </div>
      </DndProvider>
    );
  };

  const _reorderItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = dialogs[dragIndex];

    setDialogs(
      update(dialogs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem],
        ],
      }),
    );
  };

  return (
    <Modal
      onSubmit={props.onSubmit}
      onClose={props.onClose}
      size="default"
      testID="dv-modal"
      title={strings.DIALOG_MODAL_HEADLINE}
      submitText={strings.QUIZ_CHANGE}
      icon={<ChatBubbleOutlineIcon />}>
      <>
        {_renderDialogs()}

        <Button
          onClick={() => {
            setDialogs((dialogs) => [
              ...dialogs,
              {
                id: undefined,
                subtitle: '',
                person: 'a',
                key: uniqueId('dialog-'),
              },
            ]);
          }}
          variant="outlined"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          sx={{width: '100%', mt: '40px'}}>
          {strings.DIALOG_MODAL_ADD_DIALOG}
        </Button>
      </>
    </Modal>
  );
};
