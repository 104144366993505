import '@fontsource/roboto';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {worker} from 'api/mock/worker';
import {config} from 'settings';
import React, {useEffect, useState} from 'react';
import ReactDOMClient from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {mst} from 'reactotron-mst';
import Reactotron, {trackGlobalErrors} from 'reactotron-react-js';
import {RootStore, RootStoreProvider, setupRootStore} from 'store/root-store';
import App from './App';
import {theme} from './settings/theme';
import {initializeSentry} from './helper/initializeSentry';

if (config.environment === 'development') {
  Reactotron.configure({host: 'localhost', port: 3001})
    .use(trackGlobalErrors({}))
    .use(mst())
    .connect();
}

if (config.environment !== 'development') {
  initializeSentry();
}

const Main = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    document.body.style.backgroundColor = '#EFEFEF';
    setupRootStore().then(async (a) => {
      if (config.api === 'development' || window.Cypress) {
        await worker.start({onUnhandledRequest: 'warn'});
      }
      setRootStore(a);
    });
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <React.StrictMode>
      <RootStoreProvider value={rootStore}>
        <React.StrictMode>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </React.StrictMode>
      </RootStoreProvider>
    </React.StrictMode>
  );
};

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<Main />);
