import TextField, {TextFieldProps} from '@mui/material/TextField';
import './videoIdInput.css';
import React from 'react';

export const VideoIdInput = (props: TextFieldProps) => {
  const inputRef = React.useCallback(
    (node: HTMLInputElement | null) => {
      if (node && props.autoFocus) {
        // Use a timeout to ensure the input is ready for focus.
        // Material-UI's TextField wraps the <input>, so direct focus may fail immediately after render.
        setTimeout(() => node.focus(), 0);
      }
    },
    [props.autoFocus],
  );

  return (
    <TextField
      inputRef={inputRef}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      type="number"
      label={'ID'}
      data-videoidinput
      {...props}
    />
  );
};
